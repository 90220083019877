import React, { Component } from "react";
import headerLogo from "../../images/logo-white.svg";
import { NavLink } from "react-router-dom";
import Navigation from "./navigation";
import Account from "./account";

class Header extends Component {
  render() {
    const { user } = this.props;

    return (
      <div className="wrm-header">
        <div className="wrm-wrapper">
          <NavLink to="/" className="wrm-header-logo">
            <img src={headerLogo} alt="Wind River Logo" />
          </NavLink>
          <Account user={user} />
          <Navigation />
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}

export default Header;