import React, { Component } from "react";
import processorArchitectureIcon from "../../images/processor-architecture-tile-icon.png";
import operatingSystemIcon from "../../images/operating-systems-tile-icon.png";
import harwareVendorIcon from "../../images/hardware-vendors-tile-icon.png";
import { Link } from "react-router-dom";
import GA from '../../utils/GoogleAnalytics';

class BspCategories extends Component {
  render() {
    return (
      <div
        className="wrm-resources"
        style={{ marginLeft: "-15px", marginRight: "-15px" }}
      >
        <Link 
          to="/architectures" 
          className="wrm-resources-tile text-center"
          onClick={()=> GA.event('BSPs by', 'Tile', 'Processor Architectures')}
        >
          <img
            src={processorArchitectureIcon}
            alt="Processor Architectures Icon"
          />
          <h3 className="wrm-is-medium">Processor Architectures</h3>
        </Link>
        <Link 
          to="/products" 
          className="wrm-resources-tile text-center"
          onClick={()=> GA.event('BSPs by', 'Tile', 'Operating Systems')}
        >
          <img src={operatingSystemIcon} alt="Operating System Icon" />
          <h3 className="wrm-is-medium">Operating Systems</h3>
        </Link>
        <Link 
          to="/vendors" 
          className="wrm-resources-tile text-center"
          onClick={()=> GA.event('BSPs by', 'Tile', 'Hardware Vendors')}
        >
          <img src={harwareVendorIcon} alt="Harware Vendor Icon" />
          <h3 className="wrm-is-medium">Hardware Vendors</h3>
        </Link>
      </div>
    );
  }
}

export default BspCategories;
