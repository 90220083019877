import React, { Component } from "react";
import GA from '../../utils/GoogleAnalytics';
import courseIcon from "../../images/software-support-agreement-icon.png";

class BspCourses extends Component {
  render() {
    const { courses, coursesLastItem } = this.props;

    return (
      <div className="row">
        {courses.map(course => (
          <div key={course._id} className="col-xs-12 col-sm-6 col-lg-3">
            <a 
              href={course.url} 
              className="wrm-psp-tile" 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={()=> GA.event('BSP Training Courses', 'External Link', course.title)}
            >
              <img src={courseIcon} alt="Course" />
              <p className="course-title">
                <b>{course.title}</b>
              </p>
              <p className="training_courses_description">
                {course.description}
              </p>
            </a>
          </div>
        ))}
        <div className="col-xs-12 col-sm-6 col-lg-3">
          <a 
            href={coursesLastItem.url} 
            className="wrm-psp-tile wrm-psp-tile-featured" 
            target="_blank" 
            rel="noopener noreferrer"
            onClick={()=> GA.event('BSP Training Courses | ' + coursesLastItem.title, 'External Link', 'Learn More')}
          >
            <p className="course-title text-uppercase">
              <b>{coursesLastItem.title}</b>
            </p>
            <p className="training_courses_description">
              {coursesLastItem.description}{" "}
              <span className="course-learn-more">Learn More</span>{" "}
              <span className="fa fa-angle-right"></span>
            </p>
          </a>
        </div>
      </div>
    );
  }
}

export default BspCourses;
