import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "./../../common/table";
import GA from '../../utils/GoogleAnalytics';

class VendorsTable extends Component {
  columns = [
    {
      key: 'vendor',
      content: vendor => (
        <Link 
          to={`/bsps/vendor/${vendor._id}`}
          onClick={()=> GA.event('Vendors List', 'Link', vendor.name)}
        >
          {vendor.name}
        </Link>
      )
    },
    {
      key: 'bsps',
      alignRight: true,
      content: vendor => (
        <Link 
          to={`/bsps/vendors/${vendor._id}`}
          onClick={()=> GA.event('Vendors List', 'Link', vendor.name)}
        >
          <span>{vendor.totalBsps} BSPs available</span>
        </Link>
      )
    }
  ];

  render() {
    const { vendors } = this.props;

    return <Table columns={this.columns} data={vendors} />;
  }
}

export default VendorsTable;
