import { toast } from "react-toastify";
import _ from "lodash";

export function notify(notification) {
  if (!Array.isArray(notification)) {
    if (_.has(notification, 'NOTICE')) {
      notification.NOTICE.map(msg => (
        toast.warning(
          msg.body,
          { position: toast.POSITION.BOTTOM_RIGHT }
        )
      ));
    }
    if (_.has(notification, 'ERROR')) {
      notification.ERROR.map(msg => (
        toast.error(
          msg.body,
          { position: toast.POSITION.BOTTOM_RIGHT }
        )
      ));
    }
    if (_.has(notification, 'INFO')) {
      notification.INFO.map(msg => (
        toast.info(
          msg.body,
          { position: toast.POSITION.BOTTOM_RIGHT }
        )
      ));
    }
    if (_.has(notification, 'SUCCESS')) {
      notification.SUCCESS.map(msg => (
        toast.success(
          msg.body,
          { position: toast.POSITION.BOTTOM_RIGHT }
        )
      ));
    }
  }
}
