import React, { Component } from "react";
import { meta } from "../utils/meta";
import HeroSmall from "../components/hero/heroSmall";
import Breadcrumbs from "../components/container/breadcrumbs";

class NotFound extends Component {
  componentDidMount() {
    meta('Not found');
    window.scrollTo(0, 0);
  }

  render() {
    const crumbs = [{
      'label': 'Not found'
    }];

    return (
      <React.Fragment>
        <div className="wrm-content">
          <HeroSmall />
          <Breadcrumbs crumbs={crumbs} />
          <div className="wrm-section">
            <div className="wrm-wrapper">
                <h1 className="wrm-section-header text-left wrm-is-medium">Not Found</h1>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NotFound;
