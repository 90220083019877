import React, { Component } from "react";
import { meta } from "../utils/meta";
import HeroSmall from "../components/hero/heroSmall";
import Breadcrumbs from "../components/container/breadcrumbs";
import ProductsTable from "./../components/container/productsTable";
import { getProducts } from '../services/productService';
import { notify } from '../utils/notifications';
import GA from '../utils/GoogleAnalytics';

class Products extends Component {
  state = { products: [], archivedProducts: [] };

  async componentDidMount() {
    meta('Locate BSPs by Product - BSP Query Tool');
    window.scrollTo(0, 0);
    const { data: productsData } = await getProducts();
    const { content, notification } = productsData;

    notify(notification);

    const products = content.products;
    const archivedProducts = content.archivedProducts;

    this.setState({ products, archivedProducts });
    
  }

  getPageData = () => {
    const { products, archivedProducts } = this.state;

    return { data: products, dataArchived: archivedProducts };
  };

  render() {
    const { data: products, dataArchived: archivedProducts } = this.getPageData();
    const crumbs = [{
      'label': 'Products'
    }];

    return (
      <React.Fragment>
        <div className="wrm-content">
          <HeroSmall />
          <Breadcrumbs crumbs={crumbs} />
          <div className="wrm-section">
            <div className="wrm-wrapper">
              <h3 className="wrm-section-header text-left wrm-is-medium has-p no-margin">
                Wind River Products
              </h3>
              <p>For any BSP Support questions, please contact your Wind River Account Manager or Wind River Sales for <a href="https://www.windriver.com/contact" target="_blank" rel="noopener noreferrer" onClick={()=> GA.event('Wind River Products', 'External Link', 'new inquiries')}>new inquiries</a>.</p>
              <div className="wrm-downloads no-padding" style={{ marginBottom: "60px" }}>
                <div className="wrm-downloads-list wrm-results-table wrm-categorie-list no-margin-bottom">
                  <ProductsTable products={products} />
                </div>
              </div>
              <h3 className="wrm-section-header text-left wrm-is-medium has-p no-margin">
                Wind River Archived Products
              </h3>
              <p>For BSP Support information on older products or versions, please contact <a href="https://www.windriver.com/contact" target="_blank" rel="noopener noreferrer" onClick={()=> GA.event('Wind River Archived Products', 'External Link', 'Wind River Sales')}>Wind River Sales</a>.</p>
              <div className="wrm-downloads no-padding">
                <div className="wrm-downloads-list wrm-results-table wrm-categorie-list no-margin-bottom">
                  <ProductsTable products={archivedProducts} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Products;
