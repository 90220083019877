import React, { Component } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import GA from '../../utils/GoogleAnalytics';

class Account extends Component {
  renderTitle(name) {
    return <div><span className="fa fa-user"></span> {name}</div>;
  }

  render() {
    const { user } = this.props;

    return (
      <div className="wrm-header-nav is-main-menu">
        <Navbar expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              { (user && !user.auth) && (
                <React.Fragment>
                  <Nav.Link 
                    href={process.env.REACT_APP_API_URL + "/login/?link_back=" + encodeURIComponent(window.location.href)}
                    onClick={()=> GA.event('Navigation', 'External Link', 'Log in')}
                  >
                    <span className="fa fa-user"></span> Log in
                  </Nav.Link>
                  <Nav.Link 
                    href="https://wrsn.windriver.com/accounts/faces/userRegistration" 
                    className="nav-link"
                    onClick={()=> GA.event('Navigation', 'External Link', 'Register')}
                  >
                    Register
                  </Nav.Link>
                </React.Fragment>
              )}
              {user.auth && (
                <NavDropdown 
                  title={this.renderTitle(user.display_name)}
                  onClick={()=> GA.event('Navigation', 'Dropdown Menu', user.display_name)}
                >
                  <NavDropdown.Item 
                    href="https://wrsn.windriver.com/accounts/faces/editUser" 
                    className="dropdown-item"
                    onClick={()=> GA.event('Navigation', 'External Link', user.display_name + ' | Settings')}
                  >
                    Settings
                  </NavDropdown.Item>
                  <NavDropdown.Item 
                    href={process.env.REACT_APP_API_URL + "/logout/?link_back=" + encodeURIComponent(window.location.href)} 
                    className="dropdown-item"
                    onClick={()=> GA.event('Navigation', 'External Link', user.display_name + ' | Logout')}
                  >
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Account;
