import React, { Component } from "react";
import { meta } from "../utils/meta";
import HeroSmall from "../components/hero/heroSmall";
import Breadcrumbs from "../components/container/breadcrumbs";
import VendorsTable from "./../components/container/vendorsTable";
import { getVendors } from '../services/vendorService';
import { notify } from '../utils/notifications';

class Vendors extends Component {
  state = { vendors: [] };

  async componentDidMount() {
    meta('Locate BSPs by Hardware Vendor - BSP Query Tool');
    window.scrollTo(0, 0);
    const { data } = await getVendors();
    const { content: vendors, notification } = data;

    notify(notification);

    this.setState({ vendors });
    
  }

  getPageData = () => {
    const { vendors } = this.state;

    return { data: vendors };
  };

  render() {
    const { data: vendors } = this.getPageData();
    const crumbs = [{
      'label': 'Hardware Vendors'
    }];

    return (
      <React.Fragment>
        <div className="wrm-content">
          <HeroSmall />
          <Breadcrumbs crumbs={crumbs} />
          <div className="wrm-section">
            <div className="wrm-wrapper">
              <div className="row">
                <div className="col-xs-12 col-sm-12">
                  <h3 className="wrm-section-header text-left wrm-is-medium has-p no-margin">
                    Select a Hardware Vendor
                  </h3>
                </div>
              </div>
              <div className="wrm-downloads no-padding">
                <div className="wrm-downloads-list wrm-results-table wrm-categorie-list no-margin-bottom">
                  <VendorsTable vendors={vendors} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Vendors;
