import React, { Component } from "react";
import { meta } from "../utils/meta";
import { Link } from "react-router-dom";
import HeroLarge from "../components/hero/heroLarge";
import BspCategories from "../components/container/listBspCategories";
import GA from '../utils/GoogleAnalytics';
import ContactForm from '../components/container/contactForm';
class Home extends Component {
  componentDidMount() {
    meta('BSP QUERY TOOL');
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <div className="wrm-content">
          <HeroLarge />
          <div className="wrm-section has-bg">
            <div className="wrm-wrapper">
              <h2 className="wrm-section-header text-left text-uppercase wrm-is-medium pull-left has-p">
                Board Support Packages
              </h2>
              <p className="wrm-section-p-left-align-home pull-left text-left pdb-15">
                Together with our partners, Wind River<sup>&reg;</sup> offers the most extensive range of board support packages (BSPs) in the embedded software industry. These software kits make it quick and easy to use our operating systems with nearly any type of hardware device.
              </p>
              <div className="clearfix"></div>
              <p
                className="p-18 wrm-section-p-left-align text-left"
                style={{ paddingBottom: "20px" }}
              >
                <b>Browse our BSPs by the following categories:</b>
              </p>
              <BspCategories />
              <div className="wrm-resources-tile text-left pdt-20">
                <p className="p-20">
                  <div className="cta-contact">
                    <ContactForm />
                  </div>
                  <Link 
                    to="/bsp" 
                    className="text-uppercase text-medium"
                    onClick={()=> GA.event('Home | BOARD SUPPORT PACKAGES', 'Link', 'Learn More')}
                  >
                    Learn More <span className="fa fa-angle-right"></span>
                  </Link>
                  
                </p>
              </div>
            </div>
          </div>

          <div className="wrm-section">
            <div className="wrm-wrapper">
              <h2 className="wrm-section-header text-left text-uppercase wrm-is-medium has-p">
                Software Partners
              </h2>
              <div className="row">
                <div className="col-xs-12 col-sm-5">
                  <div className="text-left">
                    <p className="p-16">
                      Add to the functionality of your Wind River
                      <sup>
                        <small>®</small>
                      </sup>
                      operating systems with best-in-class software from our
                      trusted partners. These packages are tested and validated
                      for seamless interoperability to help accelerate your
                      development processes and foster design innovations.
                    </p>
                    <p className="p-20 pdt-20">
                      <Link 
                        to="/partners" 
                        className="text-uppercase text-medium"
                        onClick={()=> GA.event('Home | SOFTWARE PARTNERS', 'Tile', 'View All')}
                      >
                        View All <span className="fa fa-angle-right"></span>
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-1 pdb-30"></div>
                <div className="col-xs-12 col-sm-6">
                  <div className="text-left">
                    <p className="p-20 wrm-is-medium">
                      Join the Wind River Partner Ecosystem
                    </p>
                    <p className="p-16">
                      Define your objectives and goals for partnering with us.
                      Then fill out the membership form with a focus on
                      identifying the benefits that our combined technologies
                      and expertise will bring to our mutual customers.
                    </p>
                    <p className="p-20 pdt-20">
                      <a 
                        href="https://www.windriver.com/partners/partner-program/application" 
                        className="text-uppercase text-medium" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        onClick={()=> GA.event('Home | SOFTWARE PARTNERS', 'External Link', 'Become a Partner')}
                      >
                        Become a Partner{" "}
                        <span className="fa fa-angle-right"></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
 