import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "./../../common/table";
import GA from '../../utils/GoogleAnalytics';

class ProductsTable extends Component {
  columns = [
    {
      key: 'product',
      content: product => (
        <Link 
          to={`/bsps/product/${product._id}`}
          onClick={()=> GA.event('Products List', 'Link', product.name)}
        >
          {product.name}
        </Link>
      )
    },
    {
      key: 'bsps',
      alignRight: true,
      content: product => (
        <Link 
          to={`/bsps/product/${product._id}`}
          onClick={()=> GA.event('Products List', 'Link', product.name)}
        >
          <span>{product.totalBsps} BSPs available</span>
        </Link>
      )
    }
  ];

  render() {
    const { products } = this.props;

    return <Table columns={this.columns} data={products} />;
  }
}

export default ProductsTable;
