import React, { Component } from "react";
import GA from '../../utils/GoogleAnalytics';
import footerLogo from "../../images/logo-white.svg";
import linkedinIcon from "../../images/footer-menu-linkedin.png";
import twitterIcon from "../../images/footer-menu-twitter.png";
import youtubeIcon from "../../images/footer-menu-youtube.png";
import facebookIcon from "../../images/footer-menu-facebook.png";

class Footer extends Component {
  render() {
    return (
      <div className="wrm-footer">
        <div className="wrm-wrapper">
          <div className="wrm-footer-logo">
            <a 
              href="https://www.windriver.com" 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={()=> GA.event('Footer', 'Image', 'Logo')}
            >
              <img src={footerLogo} alt="Wind River" />
            </a>
          </div>
          <div className="clearfix wrm-clear-mobile"></div>
          <div className="wrm-footer-menus">
            <div className="wrm-footer-menu-main">
              <ul>
               
                <li>
                  <a 
                    href="https://www.windriver.com/company/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    onClick={()=> GA.event('Footer Navigation', 'External Link', 'About Wind River')}
                  >
                    About Wind River
                  </a>
                </li>
                <li>
                  <a 
                    href="https://www.windriver.com/news/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    onClick={()=> GA.event('Footer Navigation', 'External Link', 'Newsroom')}
                  >
                    Newsroom
                  </a>
                </li>
                <li>
                  <a 
                    href="https://www.windriver.com/contact" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    onClick={()=> GA.event('Footer Navigation', 'External Link', 'Contact Us')}
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
              <div className="clearfix"></div>
            </div>
            <div className="wrm-footer-menu-secondary">
              <ul>
                <li>
                  <a 
                    href="https://www.windriver.com/company/terms/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    onClick={()=> GA.event('Footer Secondary Navigation', 'External Link', 'Terms of Use')}
                  >
                    Terms of Use
                  </a>
                </li>
                <li>
                  <a 
                    href="https://www.windriver.com/company/terms/privacy.html" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    onClick={()=> GA.event('Footer Secondary Navigation', 'External Link', 'Privacy')}
                  >
                    Privacy
                  </a>
                </li>
                <li>
                  <a 
                    href="https://wrsn.windriver.com/accounts/faces/editUser" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    onClick={()=> GA.event('Footer Secondary Navigation', 'External Link', 'My Account')}
                  >
                    My Account
                  </a>
                </li>
                <li>
                  <a 
                    href="https://support.windriver.com/ols/forms/olsfeedback.php" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    onClick={()=> GA.event('Footer Secondary Navigation', 'External Link', 'Feedback')}
                  >
                    Feedback
                  </a>
                </li>
                <li>&copy; {(new Date().getFullYear())} Wind River Systems, Inc.</li>
              </ul>
              <div className="clearfix"></div>
            </div>
          </div>
          <div className="wrm-footer-social-menu">
            <ul>
              <li>
                <a 
                  href="https://www.linkedin.com/company/wind-river" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  onClick={()=> GA.event('Footer Social Media', 'Image', 'Linkedin')}
                >
                  <img src={linkedinIcon} alt="Linkedin" />
                </a>
              </li>
              <li className="wrm-is-fb">
                <a 
                  href="https://www.facebook.com/WindRiverSystems?sk=wall" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  onClick={()=> GA.event('Footer Social Media', 'Image', 'Facebook')}
                >
                  <img src={facebookIcon} alt="Facebook" />
                </a>
              </li>
              <li>
                <a 
                  href="https://twitter.com/WindRiver" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  onClick={()=> GA.event('Footer Social Media', 'Image', 'Twitter')}
                >
                  <img src={twitterIcon} alt="Twitter" />
                </a>
              </li>
              <li>
                <a 
                  href="https://www.youtube.com/user/windriverchannel" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  onClick={()=> GA.event('Footer Social Media', 'Image', 'Youtube')}
                >
                  <img src={youtubeIcon} alt="Youtube" />
                </a>
              </li>
            </ul>
            <div className="clearfix"></div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}

export default Footer;
