import React, { Component } from "react";
import { meta } from "../utils/meta";
import HeroSmall from "../components/hero/heroSmall";
import Breadcrumbs from "../components/container/breadcrumbs";
import BspsTable from "./../components/container/bspsTable";
import { getBsps } from '../services/bspService';
import { notify } from './../utils/notifications';
import _ from "lodash";


class Bsps extends Component {
  state = { 
    bsps: [],
    title: '',
    sortColumn: { path: "architecture_family__c", order: "asc" },
    filterBy: []
  };

  async populateBsps() {
    const { type, id: typeId } = this.props.match.params;
    const { data } = await getBsps(type, typeId);
    const { content, notification, code } = data;

    if (code === 404) {
      this.props.history.replace("/not-found");
    } else {
      notify(notification);
      meta('BSPs for ' + content.title + ' - BSP Query Tool');

      const { bsps, title } = content;
      this.setState({ bsps, title });
    }
  }

  async componentDidMount() {
    await this.populateBsps();
    window.scrollTo(0, 0);
  }

  getPageData = () => {
    const { bsps: allBsps, sortColumn, filterBy } = this.state;

    let filtered = allBsps;
    for (let i  = 0; i < filterBy.length; i++) {
      if (filterBy[i].selectedValue !== 'All') {
        filtered = filtered.filter(m => m[filterBy[i].path] === filterBy[i].selectedValue);
      }
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const bsps = sorted;

    return { data: bsps };
  };

  getPageSubtitle = () => {
    const { type } = this.props.match.params;
    const { title } = this.state;

    switch (type) {
      case 'architecture':
        return <h4>Architecture: {title}</h4>
      case 'vendor':
        return <h4>Hardware Vendor: {title}</h4>
      case 'product':
        return <h4>Product: {title}</h4>
      default:
        break;
    }
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleFilter = data => {
    this.setState({ filterBy: data });
  };

  render() {
    const { data: bsps } = this.getPageData();
    const { sortColumn, filterBy, title } = this.state;
    const { type } = this.props.match.params;

    let crumbs = [];

    switch (type) {
      case 'architecture':
        crumbs = [{
          'path': '/architectures',
          'label': 'Architectures'
        }];
        break;
      case 'vendor':
        crumbs = [{
          'path': '/vendors',
          'label': 'Vendors'
        }];
        break;
      case 'product':
        crumbs = [{
          'path': '/products',
          'label': 'Products'
        }];
        break;
      default:
        break;
    }

    crumbs = [
      ...crumbs,
      {
        'label': title
      }
    ];

    return (
      <React.Fragment>
        <div className="wrm-content">
          <HeroSmall />
          <Breadcrumbs crumbs={crumbs} />
          <div className="wrm-section">
            <div className="wrm-wrapper">
              <div className="row">
                <div className="col-xs-12 col-sm-12">
                  <h3 className="wrm-section-header text-left wrm-is-medium has-p no-margin">
                    Board Support Packages
                  </h3>
                  {this.getPageSubtitle()}
                </div>
              </div>
              <div className="wrm-downloads no-padding">
                <div className="wrm-downloads-list wrm-results-table no-margin-bottom">
                  <BspsTable 
                    bsps={bsps} 
                    type={type} 
                    sortColumn={sortColumn} 
                    filterBy={filterBy} 
                    onSort={this.handleSort}
                    onFilter={this.handleFilter}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Bsps;
