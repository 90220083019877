import React, { Component } from "react";
import GA from '../../utils/GoogleAnalytics';

class BspInstructions extends Component {
  render() {
    const { downloadUrl, downloadLabel } = this.props;

    return (
      <React.Fragment>
        <h2 className="wrm-section-header text-left wrm-is-medium has-p">Instructions</h2>
        <p className="p-18">Follow the instructions below for your Wind River product and supported host, additional instructions may be included in the downloaded package in the file readme_install.txt</p>
        <div className="pdb-40">
          <a 
            href={downloadUrl} 
            className="btn btn-blue wrsn-is-medium" 
            target="_blank" 
            rel="noopener noreferrer"
            onClick={()=> GA.event('BSP Details Instructions', 'Button', downloadLabel)}
          >
            <span className="fa fa-download"></span>&nbsp;&nbsp;{downloadLabel}
          </a>
        </div>

        <h3 className="wrm-section-subheader wrm-is-medium pdt-10">For Wind River Linux 4.x &amp; Wind River Linux 5 BSP's:</h3>
        <p className="p-16">Wind River Linux 4.x &amp; Wind River Linux 5 BSP's listed on OLS are now only available via Electronic Software Distribution (ESD) method obtained via the Win d River Installation Tool (wrInstaller) available within your product installation.</p>
        <p className="p-16">Installation steps:</p>
        <ul className="wrm-default-list">
          <li>Please read the instructions from the zip package</li>
        </ul>

        <hr/>

        <h3 className="wrm-section-subheader wrm-is-medium pdt-10">For Vxworks 6.9, 6.7 and 6.8 BSP's:</h3>
        <ul className="wrm-default-list">
          <li>Please read the instructions from the zip package</li>
        </ul>
        <ul className="wrm-default-list">
          <li>
            Step 4 - Install.
            <ul className="wrm-default-list">
              <li>Launch Installer</li>
              <li>Windows hosts:
                <ul className="wrm-default-list">
                  <li>
                    Launch installer from Start Button:<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wind River -&gt; Product Maintenance -&gt; W Installation at XX
                  </li>
                  <li>
                    Windows hosts can optionally install the patch from the command shell:
                    <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[installDir]\maintenance\wrInstaller\x86-win32\wrInstaller.exe
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>

        <hr/>

        <h3 className="wrm-section-subheader wrm-is-medium pdt-10">For Wind River Linux 3.0 BSP's:</h3>
        <p className="p-16">Wind River Linux 3.0 BSP's available on OLS are now installed by the Wind River Installation Tool (wrInstaller) available within your product installation.</p>
        <p className="p-16">Installation steps:</p>
        <ul className="wrm-default-list">
          <li>Unzip the OLS BSP zip under [install_dir]/updates </li>
          <li>From the [install_dir]/updates directory, run the       command "../maintenance/wrInstaller/x86-linux2/wrInstaller" </li>
          <li>At the "Choose Installation Type" screen       select 'Patch', click Next </li>
          <li>Select the BSP you wish to install, click Install </li>
          <li>Once the install process completes, click Finish </li>
          <li>When you wish to build the BSP ensure you add       "--with-layer=" to your configure line with the full path to the       installed BSP layer found under the wrlinux-3.0/layers directory</li>
        </ul>
      </React.Fragment>
    );
  }
}

export default BspInstructions;
