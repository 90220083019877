import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import NotFound from "./pages/notFound";
import Home from "./pages/home";
import Bsp from "./pages/bsp";
import Architectures from "./pages/architectures";
import Vendors from "./pages/vendors";
import Products from "./pages/products";
import Bsps from "./pages/bsps";
import BspDetails from "./pages/bspDetails";
import Partners from "./pages/partners";
import SearchResults from "./pages/searchResults";
//import MarketplaceTermsOfUse from "./pages/marketplaceTermsOfUse";
import { ToastContainer } from "react-toastify";
import { notify } from './utils/notifications';
import GA from './utils/GoogleAnalytics';
import { userAuth } from './services/authService';
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

class App extends Component {
  state = {
    user: {
      auth: false
    }
  };

  async componentDidMount() {
    const { data } = await userAuth();
    const { content: user, notification } = data;

    notify(notification);

    this.setState({ user });
  }

  render() {
    const { user } = this.state;

    return (
      <React.Fragment>
        { GA.init() && <GA.RouteTracker /> }
        <ToastContainer />
        <Header user={user} />
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/bsp" component={Bsp} />
          <Route path="/partners" component={Partners} />
          <Route path="/architectures" component={Architectures} />
          <Route path="/vendors" component={Vendors} />
          <Route path="/products" component={Products} />
          <Route path="/bsps/:type/:id" component={Bsps} />
          <Route path="/bsps/:id" component={BspDetails} />
          <Route path="/search" component={SearchResults} />
          <Route path="/partners" component={Partners} />
          <Route path="/not-found" component={NotFound} />
          <Redirect from="/" exact to="home" />
          <Redirect to="/not-found" />
        </Switch>
        <Footer />
      </React.Fragment>
    );
  }
}

export default App;
