
import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import GA from '../../utils/GoogleAnalytics';


const ContactForm = () => {

    const urlMarketo = "https://lp.windriver.com/js/forms2/js/forms2.min.js";

    useEffect(() => {
        const script = document.createElement('script');
        script.src = urlMarketo;
        script.async = true;
        document.body.appendChild(script);
    
    }, [urlMarketo]);
   

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        GA.event('Intro', 'Contact Us', 'Open Form');
        setShow(true);

        window.MktoForms2.loadForm("//lp.windriver.com", "113-TSG-922", 2938, function (form) {
            

            if(document.getElementById("LblWeb_Comments__c")){
                let comment_wrapper  = document.getElementById("LblWeb_Comments__c").parentElement.parentElement.parentElement;
                comment_wrapper.classList.add('comment-box');
            }
            
            let formHTML = (form.getFormElem())[0];
            let fields  = Array.from(formHTML.getElementsByTagName('input'));
            if(fields.length > 0){
                fields.map(item => {
                    if(item.attributes && item.attributes.type.nodeValue === "hidden"){
                        let parent = item.parentElement;
                        parent.classList.add('hidden-box');
                    }
                    return;
                })
            }

            /*
            if( document.getElementById("Email_Opt_In__c")) {
                let email_c_wrapper  = document.getElementById("Email_Opt_In__c").parentElement.parentElement.parentElement;
                email_c_wrapper.classList.add('consent-box');
            }
            */


            document.getElementById("formContactLoader").classList.add('hideit');
                form.onSuccess(function (values, followUpUrl) {
                    let formInit = document.getElementById("formInit");
                    formInit.classList.add('hideit');
                    let formResponse = document.getElementById("formResponse");
                    formResponse.classList.add('showit');
                    return false;
                });
        });
    };

    return (
        <>
        
            <Button variant="primary" onClick={handleShow}>
                    Contact Us{" "}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Contact us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-init" id="formInit">
                        <div id="formContactLoader">
                            <span className="fa fa-spinner"></span>
                        </div>
                        <form id="mktoForm_2938"></form>
                        <small>
                            <br/>
                            By submitting this form you agree that your personal information will be used by Wind River to process this request and send you further information about Wind River products and services pursuant to our privacy notice located here: <a href="https://windriver.com/company/terms/privacy" target="_blank"  rel="noopener noreferrer">windriver.com/company/terms/privacy</a>.
                        </small>
                    </div>

                    <div className="form-end" id="formResponse">
                        <p><b>Thank you for your interest. A representative will contact you shortly</b></p>
                    </div>

                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
            </>
    );
 
}


export default ContactForm;