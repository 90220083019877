import React, { Component } from "react";
import { meta } from "../utils/meta";
import HeroSmall from "../components/hero/heroSmall";
import Breadcrumbs from "../components/container/breadcrumbs";
import BspInstructions from "../components/container/bspInstructions";
import { getBspById } from "../services/bspService";
import { notify } from './../utils/notifications';
import GA from '../utils/GoogleAnalytics';
import RefContactForm from '../components/container/refContactForm';


class BspDetails extends Component {
  state = {
    data: {
      name: "",
      bsp_download_link__c: "",
      bsp_source__c: "",
      certification_level__c: "",
      host_support__c: "",
      readme_download_link__c: "",
      version__c: "",
      workbench_agent__c: "",
      wr_os__c: "",
      features: [],
      boards: [],
      download__c: false,
      download_message__c: "",
      features_summary_list__c: "",
    },
    showInstructions: false,
    downloadUrl: "",
    downloadLabel: ""
  };

  async populateBsp() {
    const bspId = this.props.match.params.id;
    const { data } = await getBspById(bspId);
    const { content: bsp, notification, code } = data;

    if (code === 404) {
      this.props.history.replace("/not-found");
    } else {
      notify(notification);
      meta(bsp.name + ' - BSP Query Tool');

      this.setState({ data: this.mapToViewModel(bsp) });
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.populateBsp();
  }

  mapToViewModel(bsp) {
    return {
      _id: bsp.bsp__c_id,
      name: bsp.name,
      bsp_download_link__c: bsp.bsp_download_link__c,
      bsp_source__c: bsp.bsp_source__c,
      certification_level__c: bsp.certification_level__c,
      host_support__c: bsp.host_support__c,
      readme_download_link__c: bsp.readme_download_link__c,
      version__c: bsp.version__c,
      workbench_agent__c: bsp.workbench_agent__c,
      wr_os__c: bsp.wr_os__c,
      features: bsp.features,
      boards: bsp.boards,
      download__c: bsp.download__c,
      download_message__c: bsp.download_message__c,
      features_summary_list__c: bsp.features_summary_list__c
    };
  }

  renderRow(label, value) {
    let html = '';

    if (value && value.length > 0) {
      if (label === "Boards") {
        html = (
          <div>
            <h3 className="wrm-subsection-header wrm-is-medium">{label}</h3>
            {this.renderValue(label, value)}
          </div>
        );
      } else {
        html = (
          <div className="row">
            <div className="col-xs-12 col-md-3 description_title">
              <p className="p-18"><b>{label}</b></p>
            </div>
            <div className="col-xs-12 col-md-9 description_details">
              {this.renderValue(label, value)}
            </div>
          </div>
        );
      }
    }

    return html;
  }

  renderProcessorFeatures(value) {
    let html = '';

    if (value && value.length > 0) {
      html = (
        <div>
          Features:
          <span className="wrm-row-indent">
            {value.map(procFeature => (
              <span key={procFeature.feature__c_id}>
                {procFeature.name}<br />
                <i>{procFeature.description}</i><br />
              </span>
            ))}
          </span>
        </div>
      );
    }

    return html;
  }

  renderProcessorRow(label, value) {
    let html = '';

    if (value && value.length > 0) {
      html = <div>{label}: {value}</div>;
    }

    return html;
  }

  renderValue(label, value) {
    let html = '';

    switch (label) {
      case 'Features':
        html = (
          <div>
            {value.map(feature => (
              <div key={feature.feature__c_id}>
                <h6 className="wrm-is-medium">{feature.name}</h6>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        );
        break;
      case 'Boards':
        html = (
          <div>
            {value.map(board => (
              <div className="pdb-40" key={board.board__c_id}>
                {this.renderRow("Board Name", board.name)}
                {this.renderRow("Vendor", board.vendor__c)}
                {this.renderRow("Primary Processor", board.primary_processor__c)}
                {this.renderRow("Features", board.features)}
                {this.renderRow("Processors", board.processors)}
              </div>
            ))}
          </div>
        );
        break;
      case 'Processors':
        html = (
          <div>
            {value.map(processor => (
              <div key={processor.processor__c_id}>
                <h6 className="wrm-is-medium">{processor.name}</h6>
                <div style={{ paddingBottom: "10px" }}>
                  {this.renderProcessorRow("Cores", processor.of_cores__c)}
                  {this.renderProcessorRow("Architecture", processor.architecture__c)}
                  {this.renderProcessorRow("Architecture Family", processor.architecture_family__c)}
                  {this.renderProcessorRow("Vendor", processor.vendor__c)}
                  {this.renderProcessorFeatures(processor.features)}
                </div>
              </div>
            ))}
          </div>
        )
        break;
      default:
        //html = (<p className="p-16">{this.newline(value)}</p>);
        html = (<p className="p-16" dangerouslySetInnerHTML={{ __html: this.newline(value) }}></p>);
        break;
    }

    return html;
  }

  newline = (text) => {
    const newText = text.replace(new RegExp('\r?\n', 'g'), '<br />');
    return newText;
  }

  handleDownloadClick = (e) => {
    const downloadUrl = e.target.dataset.url;
    const downloadLabel = e.target.dataset.label;

    GA.event('BSP Details', 'Button', downloadLabel);

    this.setState({ showInstructions: true, downloadUrl, downloadLabel });
  };

  render() {
    const { data: bsp, showInstructions, downloadUrl, downloadLabel } = this.state;
    const crumbs = [
      {
        'path': '/bsp',
        'label': 'BSPs'
      },
      {
        'label': bsp.name
      }
    ];

    return (
      <React.Fragment>
        <div className="wrm-content">
          <HeroSmall />
          <Breadcrumbs crumbs={crumbs} />
          <div className="wrm-section">
            <div className="wrm-wrapper">
              <h2 className="wrm-section-header wrm-is-medium">
                {bsp.name}
              </h2>
              {!showInstructions && (<React.Fragment>
                <div className="row">
                  <div className="col-xs-12 col-md-3 description_title"><p className="p-18"><b>Download</b></p></div>
                  {!bsp.download__c && (
                    <div className="col-xs-12 col-md-9 description_details">
                      <div>
                        <div>
                          For more details, log in or contact <RefContactForm label="Wind River Sales" />.
                        </div>
                        <a
                          href={process.env.REACT_APP_API_URL + "/login/?link_back=" + encodeURIComponent(window.location.href)}
                          className="btn btn-blue wrsn-is-medium"
                          onClick={() => GA.event('BSP Details', 'External Link', 'Log in')}
                        >
                          Log in
                        </a>&nbsp;
                      </div>
                    </div>
                  )}
                  {bsp.download__c && (
                    <div className="col-xs-12 col-md-9 description_details">
                      <div>
                        <div dangerouslySetInnerHTML={{ __html: bsp.download_message__c }}></div>
                        {bsp.bsp_download_link__c.map(btn => (
                          <div key={btn._id}>
                            <button className="btn btn-blue wrsn-is-medium" data-url={btn.url} data-label={btn.label} onClick={this.handleDownloadClick}>
                              <span className="fa fa-download" data-url={btn.url} data-label={btn.label}></span>&nbsp;&nbsp;{btn.label}
                            </button>
                            <br />
                          </div>
                        ))}
                        {bsp.readme_download_link__c.map(btn => (
                          <div key={btn._id}>
                            <a
                              href={btn.url}
                              className="btn btn-blue wrsn-is-medium"
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => GA.event('BSP Details', 'External Link', bsp.name + ' | ' + btn.label)}
                            >
                              <span className="fa fa-download"></span>&nbsp;&nbsp;{btn.label}
                            </a>
                            <br />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                {this.renderRow("Source", bsp.bsp_source__c)}
                {this.renderRow("Certification Level", bsp.certification_level__c)}
                {this.renderRow("Host Support", bsp.host_support__c)}
                {this.renderRow("Product Version", bsp.version__c)}
                {this.renderRow("OS", bsp.wr_os__c)}
                {this.renderRow("Workbench Agent", bsp.workbench_agent__c)}
                {this.renderRow("Features ", bsp.features_summary_list__c)}
                {this.renderRow("Features", bsp.features)}
                {this.renderRow("Boards", bsp.boards)}
              </React.Fragment>)}
              {showInstructions && (<BspInstructions downloadUrl={downloadUrl} downloadLabel={downloadLabel} />)}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BspDetails;
