import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { searchBsps } from './../../services/searchService';
import { notify } from './../../utils/notifications';
import GA from '../../utils/GoogleAnalytics';

class SearchBsps extends Component {
  state = { 
    bsps: [], 
    active: false
  };

  handleChange = query => {
    if (query.length > 10000) {
      const promise = searchBsps(encodeURIComponent(query));

      promise.then(({ data }) => {
        const { content: bsps, notification } = data;

        notify(notification);

        const isActive = bsps.length > 0 ? true : false;

        this.setState({ bsps, active: isActive });
      });
    } else {
      this.setState({ active: false });
    }
  };

  handleKeyUp = event => {
    if (event.keyCode === 13) {
      const query = event.currentTarget.value;
      this.props.history.push('/search/?q=' + encodeURIComponent(query) + '&pg=1');
      this.setState({ active: false });

      if (this.props.onSearch) {
        this.props.onSearch(query);
      }
    }
  };

  render() {
    const { bsps, active } = this.state;
    const dropDownClass = active ? 'dropdown-menu show' : 'dropdown-menu';

    return (
      <div className="wrm-top-search-form">
        <div className="input-group input-group-lg">
          <input 
            className="form-control wrm-top-search-input"
            placeholder="Search BSPs..."
            onChange={e => this.handleChange(e.currentTarget.value)}
            onKeyUp={e => this.handleKeyUp(e)}
          />
          <div className="wrm-top-searc-icon">
            <span className="fa fa-search"></span>
          </div>
          <div className={dropDownClass}>
            {bsps.map(item => (
              <Link 
                className="dropdown-item" 
                key={item._id} 
                to={`/bsps/${item._id}`}
                onClick={()=> GA.event('Search Form', 'Autocomplete Link', item.title)}
              >
                {item.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SearchBsps);
