import React, { Component } from "react";
import { meta } from "../utils/meta";
import HeroSmall from "../components/hero/heroSmall";
import Breadcrumbs from "../components/container/breadcrumbs";
import ArchitecturesTable from "./../components/container/architecturesTable";
import { getArchitectures } from '../services/architectureService';
import { notify } from './../utils/notifications';

class Architectures extends Component {
  state = { architectures: [] };

  async componentDidMount() {
    meta('Locate BSPs by Architecture - Wind River Marketplace');
    window.scrollTo(0, 0);
    const { data } = await getArchitectures();
    const { content: architectures, notification } = data;

    notify(notification);

    this.setState({ architectures });
    
  }

  getPageData = () => {
    const { architectures } = this.state;

    return { data: architectures };
  };

  render() {
    const { data: architectures } = this.getPageData();
    const crumbs = [{
      'label': 'Architectures'
    }];

    return (
      <React.Fragment>
        <div className="wrm-content">
          <HeroSmall />
          <Breadcrumbs crumbs={crumbs} />
          <div className="wrm-section">
            <div className="wrm-wrapper">
              <div className="row">
                <div className="col-xs-12 col-sm-12">
                  <h3 className="wrm-section-header text-left wrm-is-medium has-p no-margin">
                    Select an Architecture
                  </h3>
                </div>
              </div>
              <div className="wrm-downloads no-padding">
                <div className="wrm-downloads-list wrm-results-table wrm-categorie-list no-margin-bottom">
                  <ArchitecturesTable architectures={architectures} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Architectures;
