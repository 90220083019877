import React, { Component } from "react";
import SearchBsps from "./searchBsps";

class HeroSmall extends Component {
  render() {
    const { onSearch } = this.props;

    return (
      <div className="wrm-top wrm-is-small wrm-header-no-details">
        <div className="wrm-wrapper">
          <div className="row">
            <div className="col-xs-12 col-sm-5">
              <h1 className="tk-league-gothic text-uppercase text-left">
                BSP Query Tool
              </h1>
              <h3 className="text-left wrm-is-medium">
                Board Support Packages
              </h3>
            </div>
            <div className="col-xs-12 col-sm-1"></div>
            <div className="col-xs-12 col-sm-6">
              <SearchBsps onSearch={onSearch} />
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}

export default HeroSmall;
