import React, { Component } from "react";
import { Link } from "react-router-dom";
import GA from '../../utils/GoogleAnalytics';

class Breadcrumbs extends Component {
  renderPath = (item) => {
    return item.path ? <Link to={item.path} onClick={()=> GA.event('Breadcrumbs', 'Link', item.label)}>{item.label}</Link> : item.label;
  };

  render() {
    const { crumbs } = this.props;

    return (
      <div className="wrm-breadcrumbs">
        <div className="wrm-wrapper">
          <Link to="/" onClick={()=> GA.event('Breadcrumbs', 'Link', 'Home')}>Home</Link>
          {crumbs.map((item, i) => (
            <React.Fragment key={i}>
              <span className="fa fa-angle-right"></span>
              {this.renderPath(item)}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }
}

export default Breadcrumbs;
