import React from "react";
import TableHeader from "./tableHeader";
import TableHeaderFilter from "./tableHeaderFilter";
import TableBody from "./tableBody";

const Table = ({ columns, sortColumn, filterBy, onSort, onFilter, data, originalBsps }) => {
  if (sortColumn) {
    return (
      <table className="table table-striped">
        <TableHeaderFilter 
          columns={columns} 
          data={data} 
          originalBsps={originalBsps} 
          filterBy={filterBy} 
          onFilter={onFilter} 
        />
        <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
        <TableBody columns={columns} data={data} />
      </table>
    );
  } else {
    return (
      <table className="table table-striped">
        <TableBody columns={columns} data={data} />
      </table>
    );
  }
};

export default Table;
