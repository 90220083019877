import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "./../../common/table";
import GA from '../../utils/GoogleAnalytics';

class ArchitecturesTable extends Component {
  columns = [
    {
      key: 'architecture',
      content: architecture => (
        <Link 
          to={`/bsps/architecture/${architecture._id}`}
          onClick={()=> GA.event('Architectures List', 'Link', architecture.name)}
        >
          {architecture.name}
        </Link>
      )
    },
    {
      key: 'bsps',
      alignRight: true,
      content: architecture => (
        <Link 
          to={`/bsps/architecture/${architecture._id}`}
          onClick={()=> GA.event('Architectures List', 'Link', architecture.name)}
        >
          <span>{architecture.totalBsps} BSPs available</span>
        </Link>
      )
    }
  ];

  render() {
    const { architectures } = this.props;

    return <Table columns={this.columns} data={architectures} />;
  }
}

export default ArchitecturesTable;
