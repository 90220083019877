import React, { Component } from "react";
import { meta } from "../utils/meta";
import HeroSmall from "../components/hero/heroSmall";
import Breadcrumbs from "../components/container/breadcrumbs";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { searchBsps } from '../services/searchService';
import queryString from 'query-string';
import { paginate } from "../utils/paginate";
import { notify } from '../utils/notifications';
import GA from '../utils/GoogleAnalytics';

class SearchResults extends Component {
  state = { 
    bsps: [],
    currentPage: 1,
    pageSize: 20, 
    searchQuery: ''
  };

  componentDidMount() {
    meta('Search BSPs - BSP Query Tool');
    window.scrollTo(0, 0);
    this.handleSearch();
    
  }

  componentDidUpdate(prevProps, prevState) {
    const queryStr = queryString.parse(this.props.location.search);
    const query = queryStr.q;
    const page = parseInt(queryStr.pg);

    const queryStrPrev = queryString.parse(prevProps.location.search);
    const queryPrev = queryStrPrev.q;
    const pagePrev = parseInt(queryStrPrev.pg);

    if (queryPrev !== query || (pagePrev && page && pagePrev !== page)) {
      this.handleSearch(query, page);
    }
  }

  handlePageChange = page => {
    const queryStr = queryString.parse(this.props.location.search);
    const query = queryStr.q;

    this.props.history.push('/search/?q=' + encodeURIComponent(query) + '&pg=' + page);
    this.setState({ currentPage: page });
  };

  handleSearch = async (query, page) => {
    const queryStr = queryString.parse(this.props.location.search);

    if (query === undefined) {
      query = queryStr.q;
    }

    if (page === undefined) {
      page = parseInt(queryStr.pg);
    }

    if (query) {
      const { data } = await searchBsps(query);
      const { content: bsps, notification } = data;

      notify(notification);

      const searchElem = document.querySelector('.wrm-top-search-input');

      searchElem.value = query;
      searchElem.focus();

      this.setState({ bsps, currentPage: page, searchQuery: query });
    }
  };

  getPagedData = () => {
    const { pageSize, currentPage, bsps: allBsps } = this.state;

    const bsps = paginate(allBsps, currentPage, pageSize);

    return { totalCount: allBsps.length, data: bsps };
  };

  render() {
    const { totalCount, data: bsps } = this.getPagedData();
    const { pageSize, currentPage, searchQuery } = this.state;

    const crumbs = [{
      'label': 'Search'
    }];

    let startRange = ((currentPage - 1) * pageSize) + 1;
    let endRange = startRange + pageSize - 1;

    if (endRange > totalCount) {
      endRange = totalCount;
    }

    return (
      <React.Fragment>
        <div className="wrm-content">
          <HeroSmall onSearch={this.handleSearch} />
          <Breadcrumbs crumbs={crumbs} />
          <div className="wrm-section">
            <div className="wrm-wrapper">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="wrm-section-header text-left wrm-is-medium has-p no-margin">
                    {startRange} - {endRange} of {totalCount} results searching <i>"{searchQuery}"</i>
                  </h3>
                </div>
                <div className="col-sm-6">
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={pageSize}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                    firstPageText={''}
                    itemClassFirst={'wrm-pagination-first'}
                    prevPageText={''}
                    itemClassPrev={'wrm-pagination-prev'}
                    nextPageText={''}
                    itemClassNext={'wrm-pagination-next'}
                    lastPageText={''}
                    itemClassLast={'wrm-pagination-last'}
                  />
                </div>
              </div>
              <hr />
              {bsps.map(bsp => (
                <div key={bsp._id}>
                  <Link 
                    to={`/bsps/${bsp._id}`}
                    onClick={()=> GA.event('Search Results', 'Link', bsp.title)}
                  >
                    <p className="p-20 no-margin text-uppercase"><b>{bsp.title}</b></p>
                  </Link>
                  <p className="p-16 no-margin">{bsp.description}</p>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SearchResults;
