import React, { Component } from "react";
import SearchBsps from "./searchBsps";

class HeroLarge extends Component {
  render() {
    return (
      <div className="wrm-top wrm-is-small">
        <div className="wrm-wrapper">
          <div className="row">
            <div className="col-xs-12 col-sm-5">
              <h1 className="tk-league-gothic text-uppercase text-left">
                BSP Query Tool
              </h1>
              <h3 className="text-left wrm-is-medium">
                Enhance and Extend Your Embedded Software
              </h3>
              <p className="p-16">
                Find, download, and test-drive software that helps development
                teams speed production and create innovative products.
              </p>
            </div>
            <div className="col-xs-12 col-sm-1"></div>
            <div className="col-xs-12 col-sm-6">
              <SearchBsps />
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}

export default HeroLarge;
