import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import GA from '../../utils/GoogleAnalytics';

class Navigation extends Component {
  render() { 
    return (
      <div className="wrm-header-nav is-main-menu">
        <Navbar expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <NavDropdown 
                title="Board Support Packages"
                onClick={()=> GA.event('Navigation', 'Dropdown Menu', 'Board Support Packages')}
              >
                <NavDropdown.Item 
                  as={NavLink} 
                  to="/bsp" 
                  className="dropdown-item"
                  onClick={()=> GA.event('Navigation', 'Link', 'Board Support Packages | BSP Home')}
                >
                  BSP Home
                </NavDropdown.Item>
                <NavDropdown.Item 
                  as={NavLink} 
                  to="/architectures" 
                  className="dropdown-item"
                  onClick={()=> GA.event('Navigation', 'Link', 'Board Support Packages | Architectures')}
                >
                  Architectures
                </NavDropdown.Item>
                <NavDropdown.Item 
                  as={NavLink} 
                  to="/vendors" 
                  className="dropdown-item"
                  onClick={()=> GA.event('Navigation', 'Link', 'Board Support Packages | Hardware Vendors')}
                >
                  Hardware Vendors
                </NavDropdown.Item>
                <NavDropdown.Item 
                  as={NavLink} 
                  to="/products" 
                  className="dropdown-item"
                  onClick={()=> GA.event('Navigation', 'Link', 'Board Support Packages | Products')}
                >
                  Products
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown 
                title="Partners"
                onClick={()=> GA.event('Navigation', 'Dropdown Menu', 'Partners')}
              >
                <NavDropdown.Item 
                  as={NavLink} 
                  to="/partners" 
                  className="dropdown-item"
                  onClick={()=> GA.event('Navigation', 'Link', 'Partners | List')}
                >
                  List
                </NavDropdown.Item>
                <NavDropdown.Item 
                  href="https://www.windriver.com/partners/partner-program-application.html" 
                  className="dropdown-item" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  onClick={()=> GA.event('Navigation', 'External Link', 'Partners | Become a Partner')}
                >
                  Become a Partner
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Navigation;
