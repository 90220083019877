import React, { Component } from "react";
import { meta } from "../utils/meta";
import HeroSmall from "../components/hero/heroSmall";
import Breadcrumbs from "../components/container/breadcrumbs";
import Pagination from "react-js-pagination";
import { getPartners } from '../services/partnerService';
import { paginate } from "../utils/paginate";
import { notify } from '../utils/notifications';
import GA from '../utils/GoogleAnalytics';

class Partners extends Component {
  state = {
    partners: [],
    currentPage: 1,
    pageSize: 20
  };

  async componentDidMount() {
    meta('Partners - BSP Query Tool');
    window.scrollTo(0, 0);
    const { data } = await getPartners();
    const { content: partners, notification } = data;

    notify(notification);

    this.setState({ partners });
    
  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  render() {
    const { length: countPartners } = this.state.partners;
    const { pageSize, currentPage, partners: allPartners } = this.state;

    const partners = paginate(allPartners, currentPage, pageSize);

    const crumbs = [{
      'label': 'Partners'
    }];

    return (
      <React.Fragment>
        <div className="wrm-content">
          <HeroSmall />
          <Breadcrumbs crumbs={crumbs} />
          <div className="wrm-section has-bg">
            <div className="wrm-wrapper">
              <div className="row">
                <div className="col-xs-12 col-sm-6 text-left">
                  <h2 className="wrm-section-header text-left wrm-is-medium pull-left has-p no-margin">
                    Software Partners
                  </h2>
                </div>
                <div className="col-xs-12 col-sm-6 text-right partner-list-top-pagination">
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={pageSize}
                    totalItemsCount={countPartners}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                    firstPageText={''}
                    itemClassFirst={'wrm-pagination-first'}
                    prevPageText={''}
                    itemClassPrev={'wrm-pagination-prev'}
                    nextPageText={''}
                    itemClassNext={'wrm-pagination-next'}
                    lastPageText={''}
                    itemClassLast={'wrm-pagination-last'}
                  />
                </div>
              </div>

              <hr></hr>

              <div className="row">
                {partners.map(partner => (
                  <div
                    key={partner._id}
                    className="col-xs-12 col-sm-6 col-md-4 col-lg-4"
                  >
                    <div className="partner-container">
                      <div className="partner-image">
                        <img src={partner.image_url} alt="Partner Logo" />
                      </div>
                      <div className="partner-details">
                        <p className="p-18 text-uppercase">
                          <b>{partner.title}</b>
                        </p>
                        <p className="p-16 pdb-10">{partner.description}</p>
                        <p className="p-18" style={{ color: "#cc0000" }}>
                          <b>Packages:</b>
                        </p>
                        {partner.packages.map(link => (
                          <p key={link.title} className="partner-cta-link">
                            <a 
                              href={link.url} 
                              className="text-medium" 
                              target="_blank" 
                              rel="noopener noreferrer"
                              onClick={()=> GA.event('Partners List', 'External Link', partner.title + ' | ' + link.title)}
                            >
                              {link.title}{" "}
                              <span className="fa fa-angle-right"></span>
                            </a>
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Partners;
