import React, { Component } from "react";
import { Form } from "react-bootstrap";
import GA from '../utils/GoogleAnalytics';

class TableHeaderFilter extends Component {
  generateOptionKey = (path, i) => {
    return path + i;
  };

  raiseFilter = data => {
    this.props.onFilter(data);
  };

  handleChange = event => {
    let beforeData = [];
    const clonedData =  { ...event.target };

    for (let i in clonedData) {
      if (clonedData[i].value !== undefined) {
        beforeData.push(clonedData[i].value);
      }
    }

    const uniqueBeforeData = [ ...new Set(beforeData)];
    uniqueBeforeData.shift();

    const selectedData = {
      path: event.target.getAttribute('data-column'),
      selectedValue: event.target.value,
      values: uniqueBeforeData
    }

    const { filterBy: filters } = this.props;

    let found = false;
    for (let i = 0; i < filters.length; i++) {
      if (selectedData.path === filters[i].path) {
        found = i;
      }
    }

    if (found === false) {
      filters.push(selectedData);
    } else {
      filters[found] = selectedData;
    }

    GA.event('BSPs List', 'Table Filter', selectedData.path + ': ' + selectedData.selectedValue);

    this.raiseFilter(filters);
  };

  getUniqueOptions = column => {
    const { data: bsps, filterBy: filters } = this.props;

    const data = bsps.map(row => row[column]);

    const unique = [ ...new Set(data)];
    const sorted = unique.sort();

    let found = false;
    let values = [];
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].path === column && filters[i].selectedValue !== 'All') {
        values = filters[i].values;
        found = true;
      }
    }

    if (found === false) {
      return sorted.map((op, i) => (
        <option key={this.generateOptionKey(column, i)}>
          {op}
        </option>
      ))
    } else {
      return values.map((op, i) => (
        <option key={this.generateOptionKey(column, i)}>
          {op}
        </option>
      ))
    }
  };

  getSelectedValue = column => {
    const { filterBy } = this.props;

    if (filterBy) {
      const elem = filterBy.filter(m => m.path === column);
      const selectedValue = elem.length > 0 ? elem[0].selectedValue : 'All';

      return selectedValue;
    } else {
      return 'All';
    }
  };

  render() {
    return (
      <thead>
        <tr>
          {this.props.columns.map(column => (
            <th key={column.path || column.key}>
              <div className="wrm-is-relative">
                <Form.Control as="select" 
                  data-column={column.path} 
                  onChange={this.handleChange} 
                  value={this.getSelectedValue(column.path)}
                >
                  <option key={column.path}>All</option>
                  {this.getUniqueOptions(column.path)}
                </Form.Control>
                <div className="wrm-select-arrow">
                  <span className="fa fa-angle-down"></span>
                </div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}

export default TableHeaderFilter;
