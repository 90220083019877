import React, { Component } from "react";
import { Link } from "react-router-dom";
import { meta } from "../utils/meta";
import HeroSmall from "./../components/hero/heroSmall";
import Breadcrumbs from "./../components/container/breadcrumbs";
import { getQuickLinksOs } from '../services/quickLinksOsService';
import { getQuickLinksArchitectures } from '../services/quickLinksArchitectureService';
import { getCourses } from '../services/courseService';
import BspCategories from "../components/container/listBspCategories";
import BspCourses from "../components/container/listBspCourses";
import { notify } from './../utils/notifications';
import GA from '../utils/GoogleAnalytics';

import ContactForm from '../components/container/contactForm';

class Bsp extends Component {
  state = {
    quickLinksOs: [],
    quickLinksArchitectures: [],
    courses: [],
    coursesLastItem: {
      title: "",
      description: "",
      url: "#"
    }
  };

  async componentDidMount() {
    meta('BSP Query Tool');
    window.scrollTo(0, 0);
    const { data: dataOs } = await getQuickLinksOs();
    const { content: quickLinksOs, notification: quickLinksOsNotification } = dataOs;

    const { data: dataArchitectures } = await getQuickLinksArchitectures();
    const { content: quickLinksArchitectures, notification: quickLinksArchitecturesNotification } = dataArchitectures;

    const { data: dataCourses } = await getCourses();
    const { content: courses, notification: coursesNotification } = dataCourses;

    notify(quickLinksOsNotification);
    notify(quickLinksArchitecturesNotification);
    notify(coursesNotification);

    this.setState({
      quickLinksOs, 
      quickLinksArchitectures, 
      courses: courses.items, 
      coursesLastItem: this.mapLastCourseItem(courses.lastItem)
    });
    
  }

  mapLastCourseItem(courseLstItem) {
    return {
      title: courseLstItem.title,
      description: courseLstItem.description,
      url: courseLstItem.url
    }
  }

  render() {
    const { quickLinksOs, quickLinksArchitectures, courses, coursesLastItem } = this.state;
    const crumbs = [{
      'label': 'Board Support Packages'
    }];

    return (
      <React.Fragment>
        <div className="wrm-content">
          <HeroSmall />
          <Breadcrumbs crumbs={crumbs} />
          <div className="wrm-section">
            <div className="wrm-wrapper">
              <div className="row">
                <div className="col-xs-12 col-sm-5 text-left">
                  <p className="p-18">
                    Together with our partners, Wind River
                    <sup>
                      <small>&reg;</small>
                    </sup>{" "}
                    offers the most extensive range of board support packages
                    (BSPs) in the embedded software industry to aid you with
                    board bring-up and design.
                  </p>
                  <p className="p-18">
                    Our close relationships with silicon vendors and hardware
                    manufacturers enable us to offer ready-to-use products
                    supporting the latest processors, so you can choose the best
                    development platforms for your specific architecture.
                  </p>
                  <p className="p-18">
                    Need a custom BSP?{" "}
                    <a
                      href="https://resources.windriver.com/bsps/bsp-services"
                      className="normal"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={()=> GA.event('Intro', 'External Link', 'Wind River Professional Services')}
                    >
                      Wind River Professional Services
                    </a>{" "}
                    can tailor the BSP to your device's specific needs. Any
                    board, any architecture—we can help.
                  </p>
                  <p className="p-20 pdt-20 pdb-30">
                    <ContactForm />
                  </p>
                </div>
                <div className="col-xs-12 col-sm-1 pdb-40"></div>
                <div className="col-xs-12 col-sm-6 text-left">
                  <h2 className="wrm-section-header text-left wrm-is-medium has-p">
                    Quick Links
                  </h2>
                  <div className="row quick-links">
                    <div className="col-xs-12 col-sm-12">
                      <p className="p-16 text-uppercase wrm-is-medium">
                        Operating Systems
                      </p>
                    </div>
                    {quickLinksOs.map(os => (
                      <div key={os._id} className="col-xs-12 col-sm-6">
                        <ul className="wrm-my-products-list">
                          {os.items.map(osItem => (
                            <li key={osItem._id}>
                              <Link 
                                to={osItem.url} 
                                onClick={()=> GA.event('Quick Links | OPERATING SYSTEMS', 'Link', osItem.name)}>
                                <span className="image-icon">
                                  <img src={osItem.logo} alt={osItem.alt} />
                                </span>{" "}
                                {osItem.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                    <div className="col-xs-12 col-sm-0 col-md-1 col-lg-2"></div>
                    <div className="col-xs-12 col-sm-12 pdt-30">
                      <p className="p-16 text-uppercase wrm-is-medium">
                        Processor Architectures
                      </p>
                    </div>
                    {quickLinksArchitectures.map(architecture => (
                      <div key={architecture._id} className="col-xs-4 col-sm-4 text-left pdt-10">
                        <Link 
                          to={architecture.url}
                          onClick={()=> GA.event('Quick Links | PROCESSOR ARCHITECTURES', 'Link', architecture.alt)}>
                          <img
                            className="featured_achitectures_logos"
                            src={architecture.logo}
                            alt={architecture.alt}
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wrm-section has-bg">
            <div className="wrm-wrapper">
              <h2 className="wrm-section-header text-left wrm-is-medium">
                Search BSPs by the following:
              </h2>
              <BspCategories />
            </div>
          </div>
          <div className="wrm-section">
            <div className="wrm-wrapper">
              <h2 className="wrm-section-header text-left  wrm-is-medium">
                BSP Training Courses
              </h2>
              <BspCourses courses={courses} coursesLastItem={coursesLastItem} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Bsp;
