import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "./../../common/table";
import GA from '../../utils/GoogleAnalytics';

class BspsTable extends Component {
  columns = [
    {
      path: 'architecture_family__c',
      label: 'Architecture Family',
      content: bsp => (
        <Link 
          to={`/bsps/${bsp.bsp__c_id}`}
          onClick={()=> GA.event('BSPs List', 'Link', bsp.name)}
        >
          {bsp.architecture_family__c}
        </Link>
      )
    },
    {
      path: 'processor__c',
      label: 'Processor',
      content: bsp => (
        <Link 
          to={`/bsps/${bsp.bsp__c_id}`}
          onClick={()=> GA.event('BSPs List', 'Link', bsp.name)}
        >
          {bsp.processor__c}
        </Link>
      )
    },
    {
      path: 'board_name__c',
      label: 'Board',
      content: bsp => (
        <Link 
          to={`/bsps/${bsp.bsp__c_id}`}
          onClick={()=> GA.event('BSPs List', 'Link', bsp.name)}
        >
          {bsp.board_name__c}
        </Link>
      )
    },
    {
      path: 'vendor__c',
      label: 'Hardware Manufacturer',
      content: bsp => (
        <Link 
          to={`/bsps/${bsp.bsp__c_id}`}
          onClick={()=> GA.event('BSPs List', 'Link', bsp.name)}
        >
          {bsp.vendor__c}
        </Link>
      )
    },
    {
      path: 'version__c',
      label: 'Product Version',
      content: bsp => (
        <Link 
          to={`/bsps/${bsp.bsp__c_id}`}
          onClick={()=> GA.event('BSPs List', 'Link', bsp.name)}
        >
          {bsp.version__c}
        </Link>
      )
    },
    {
        path: 'bsp_source__c',
        label: 'BSP Supplier',
        content: bsp => (
          <Link 
            to={`/bsps/${bsp.bsp__c_id}`}
            onClick={()=> GA.event('BSPs List', 'Link', bsp.name)}
          >
            {bsp.bsp_source__c}
          </Link>
        )
      }
  ];

  productColumns = [
    {
      path: 'name',
      label: 'BSP Name',
      content: bsp => (
        <Link 
          to={`/bsps/${bsp.bsp__c_id}`}
          onClick={()=> GA.event('BSPs List', 'Link', bsp.name)}
        >
          {bsp.name}
        </Link>
      )
    },
    {
      path: 'architecture_family__c',
      label: 'Architecture Family',
      content: bsp => (
        <Link 
          to={`/bsps/${bsp.bsp__c_id}`}
          onClick={()=> GA.event('BSPs List', 'Link', bsp.name)}
        >
          {bsp.architecture_family__c}
        </Link>
      )
    },
    {
      path: 'board_name__c',
      label: 'Board',
      content: bsp => (
        <Link 
          to={`/bsps/${bsp.bsp__c_id}`}
          onClick={()=> GA.event('BSPs List', 'Link', bsp.name)}
        >
          {bsp.board_name__c}
        </Link>
      )
    },
    {
      path: 'vendor__c',
      label: 'Hardware Manufacturer',
      content: bsp => (
        <Link 
          to={`/bsps/${bsp.bsp__c_id}`}
          onClick={()=> GA.event('BSPs List', 'Link', bsp.name)}
        >
          {bsp.vendor__c}
        </Link>
      )
    }
  ];

  render() {
    const { bsps, type, onSort, onFilter, sortColumn, filterBy } = this.props;
    //let columns = type === 'product' ? this.productColumns : this.columns;
    let columns = this.columns;//type === 'product' ? this.productColumns : this.columns;
    
    return (
      <Table 
        columns={columns} 
        data={bsps} 
        sortColumn={sortColumn} 
        filterBy={filterBy} 
        onSort={onSort} 
        onFilter={onFilter} 
      />
    );

  }
}

export default BspsTable;
